/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Grid } from 'semantic-ui-react'
import { map, size } from 'lodash';

// View
import AtomTechnologiesCard from '../../Atoms/TechnologiesCard';


class MoleculeTechnologiesCardContainer extends React.Component {
  render() {
    const { list } = this.props;

    return (
      <Grid class="molecule-technologies-card-container">
        <Grid.Row>
          {
            map(list, function (row, index) {
              const { item, category } = row;
              return (
                <Grid.Column mobile={16} tablet={8} computer={4} key={index}>
                  <AtomTechnologiesCard
                    item={item}
                    category={category}
                  />
                </Grid.Column>
              )
            })
          }

        </Grid.Row>
      </Grid>
    )
  }
}
 
MoleculeTechnologiesCardContainer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.array.isRequired,
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MoleculeTechnologiesCardContainer;

