/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
// import { Grid } from 'semantic-ui-react';
import './index.scss';

// Utilities
import {get, map, size} from 'lodash';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import AtomTab from '../../Atoms/Tab';

class OrganismTabbedContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      defaultIndex: null,
    };

    // Binding
    this.handleClickLink = this.handleClickLink.bind(this);
    this.handleOpenFirstTab = this.handleOpenFirstTab.bind(this);
    this.handleTabChangeCallback = this.handleTabChangeCallback.bind(this);

  }

  componentDidUpdate(prevProps) {
    const { reportNetValue } = this.props;
    const { defaultIndex } = this.state;

    if (prevProps.reportNetValue !== reportNetValue && !defaultIndex) {

      // If user has added items
      // eslint-disable-next-line no-undef
      const hasAddedItems = get(reportNetValue, 'has_added_items', null);

      // Set default index to
      const defaultIndexTo = hasAddedItems ? 0 : 1;

      // If state is not already same
      if (defaultIndex !== defaultIndexTo) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          defaultIndex: defaultIndexTo,
        });
      }
    }
  }

  handleClickLink() {
    // If default index is not 1
    this.setState({
      defaultIndex: 1,
    });
  }

  handleOpenFirstTab() {
    this.setState({
      defaultIndex: 0,
    });
  }

  handleTabChangeCallback(activeIndex) {
    // If default index is not 1
    this.setState({
      defaultIndex: activeIndex,
    });
  }

  render() {
    const { list } = this.props;
    const { defaultIndex } = this.state;

    // Defaults
    let tabPanes = [];

    if (size(list) > 0) {

      tabPanes = map(list, function(row) {

        return {
          menu: {
            text: get(row, 'title'),
          },
          pane: get(row, 'content'),
        };
      });
    }
    
    return (
      <div className="organism-tabbed-content">
        <MoleculeSectionInfo
          title="A Technology Driven Company"
          description={
            <p>We work with latest technologies to fully cater our clients' requirements.</p>
          }
          content={(
            <AtomTab
              className="worth-tab-container"
              panes={tabPanes}
              defaultIndex={defaultIndex}
              handleTabChangeCallback={this.handleTabChangeCallback}
            />
          )}
        />
      </div>
    )
  }
}

export default OrganismTabbedContent;