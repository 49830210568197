// Basics
import React from 'react';
import './index.scss';

// Assets
import Office1 from '../../../assets/images/office1.jpg';
import Office2 from '../../../assets/images/office2.jpg';
import Office3 from '../../../assets/images/office3.jpg';
import Office4 from '../../../assets/images/office4.jpg';
import Office5 from '../../../assets/images/office5.jpg';

// View
import AtomMasonry from '../../Atoms/Masonry';

class OrganismSectionOurOffice extends React.Component {
  render() {
    const { photos } = this.props;
    return (
      <div className="organism-section-our-office">
        <AtomMasonry photosList={photos} />
      </div>
    )
  }
}

OrganismSectionOurOffice.defaultProps = {
  photos: [
    {
      src: Office1,
      width: 6,
      height: 4
    },
    {
      src: Office2,
      width: 6,
      height: 4
    },
    {
      src: Office3,
      width: 6,
      height: 4
    },
    {
      src: Office4,
      width: 6,
      height: 4
    },
    {
      src: Office5,
      width: 6,
      height: 4
    },
  ]

}
export default OrganismSectionOurOffice;