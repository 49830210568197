/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// View
import AtomIcon from "../Icon";
import AtomAnimation from '../Animation';

class AtomInfoArea extends React.Component {
  render() {
    const { iconColor, description, icon, title } = this.props;
    return (
      <AtomAnimation>
        <div className="atom-info-area">
          <div className="icon-wrapper">
            <AtomIcon
              iconColor={iconColor}
              icon={icon}
            />
          </div>

          {
            title &&
            <h5>{title}</h5>
          }
          {
            description &&
            <div className="description"> {description} </div>
          }
        </div>
      </AtomAnimation>
    )
  }
}

AtomInfoArea.propTypes = {
  iconColor: PropTypes.string,
  description: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default AtomInfoArea;

