// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import MoleculeWorkWithUsForm from '../../Molecules/WorkWithUsForm';

class OrganismSectionWorkWithUs extends React.Component {

  render() {
    const { title, description } = this.props;
    return (
      <div className="organisms-section-work-with-us">
        <MoleculeSectionInfo
          title={title}
          description={
            <HtmlParser html={description} />
          }
          content={
            <MoleculeWorkWithUsForm />
          }
        />
      </div>
    )
  }
}

OrganismSectionWorkWithUs.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default OrganismSectionWorkWithUs;