// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import {
  map,
  size
} from 'lodash';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';

class OrganismListCentralizedContent extends React.Component {

  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="organism-list-centralized-content">
        {
          map(list, (row, index) => {
            const { className, title, description } = row;
            return (
              <MoleculeSectionInfo
                key={index}
                className={className}
                title={title}
                description={<HtmlParser html={description} />}
              />

            )
          })
        }
      </div>
    )
  }
}

OrganismListCentralizedContent.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.object,
    }),
  ).isRequired,
};

export default OrganismListCentralizedContent;
