// Basics
import React from 'react';
import PropTypes from 'prop-types';

class AtomImg extends React.Component {
  render() {
    const { src } = this.props;
    return (
      <img src={src} />
    )
  }
}

AtomImg.propTypes = {
  src: PropTypes.string.isRequired,
};

export default AtomImg;
