// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import { get } from 'lodash';

// view 
import AtomSocial from '../Social';
import AtomImage from '../Image';


class AtomTeam extends React.Component {
  render() {
    const { designation, description, image, title, socialProfiles } = this.props;

    return (

      <div className="atom-team">
        {
          image && (
          <AtomImage
            source={get(image, 'localFile.childImageSharp.fluid')}
            type="fluid"
          />
        )
}
        <div className="team-container">
          {
            title &&
            <h4>{title}</h4>
          }
          {
            designation &&
            <span className="designation">{designation}</span>
          }
          {
            description &&
            <p className="small-text">{description}</p>
          }
          {
            socialProfiles && (
              <AtomSocial
                list={socialProfiles}
              />
            )
          }
        </div>

      </div>

    )
  }
}

AtomTeam.propTypes = {
  designation: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  socialProfiles: PropTypes.array,
}

export default AtomTeam;

