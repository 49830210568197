import React from 'react';
import './index.scss';
import { Tab } from 'semantic-ui-react';
import { isFunction, map } from 'lodash';

class AtomTab extends React.Component {

  constructor(props) {
    super(props);
    const { defaultIndex } = props;
    this.state = {
      activeIndex: defaultIndex || 0,
    };

    //Binding
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidUpdate(prevProps, _prevState) {
    const { defaultIndex } = this.props;
    const { activeIndex } = this.state;

    if ((prevProps.defaultIndex !== defaultIndex) && (activeIndex !== defaultIndex)) {
      this.setState({
        activeIndex: defaultIndex,
      });
    }
  }

  handleTabChange(e, { activeIndex }) {
    const { handleTabChangeCallback } = this.props;
    this.setState({ activeIndex });

    // Callback
    if (isFunction(handleTabChangeCallback)) {
      handleTabChangeCallback(activeIndex);
    }

    // Resize event
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const { panes, secondary = true, pointing = true, renderActiveOnly = false } = this.props;
    const { activeIndex } = this.state;

    // Iterating all panes
    let tabPanes = map(panes, function (row, index) {
      const { menu, loading, pane } = row;

      // Return prepared pane
      return {
        key: index,
        menuItem: { key: index, icon: menu.icon, content: menu.text, className: 'custom-tab-menu' },
        pane: {
          key: index,
          content: (
            <Tab.Pane
              loading={loading}
            >
              {pane}
            </Tab.Pane>
          ),
        },
      };
    });

    return (
      <div className="atom-tab">
        <Tab
          activeIndex={activeIndex}
          menu={{ secondary: secondary, pointing: pointing }}
          panes={tabPanes}
          renderActiveOnly={renderActiveOnly}
          onTabChange={this.handleTabChange}
        />
      </div>
    );
  }
}

export default AtomTab;