// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// Utilities
import { map, size } from 'lodash';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import MoleculesJobList from '../../Molecules/JobList';

class OrganismJobContent extends React.Component {
  render() {
    const { title, description } = this.props;
    return (
      <div className="organism-job-content">
        <MoleculeSectionInfo
          title={title}
          description={description}
          content={
            <MoleculesJobList />
          }
        />

      </div>
    )
  }
}

OrganismJobContent.defaultProps = {
  title: 'We help businesses grow',
  description: (
    <p>Start building fast, beautiful and modern looking websites in no time using our theme.</p>
  ),
}

OrganismJobContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default OrganismJobContent;