/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import AtomAnimation from '../Animation';

// View
import AtomIcon from "../Icon";


class AtomAddressInfo extends React.Component {
  render() {
    const { content, iconColor, icon, title } = this.props;
    return (
      <AtomAnimation>
        <div className="atom-address-info">
          <AtomIcon
            iconColor={iconColor}
            icon={icon}
          />
          {
            title &&
            <h4>{title}</h4>
          }
          {
            content &&
            <div className="info-content small-text">{content}</div>
          }
        </div>
      </AtomAnimation>
    )
  }
}


AtomAddressInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object,
  iconColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}


export default AtomAddressInfo;

