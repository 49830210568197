// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';
import { Grid } from 'semantic-ui-react'

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// view 
import AtomTeam from '../../Atoms/Team';

class MoleculeTeam extends React.Component {
  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <Grid className="molecule-team">
        <Grid.Row>
          {
            map(list, function (row, index) {
              const { designation, description, image, title, socialProfiles } = row;

              return (
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={16}
                >
                  <AtomTeam
                    kay={index}
                    designation={designation}
                    description={<HtmlParser html={description} />}
                    image={image}
                    title={title}
                    socialProfiles={socialProfiles}
                  />
                </Grid.Column>
              )
            })
          }
        </Grid.Row>
      </Grid>
    )
  }
}

MoleculeTeam.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      designation: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default MoleculeTeam;

