// Basics
import React from 'react';
import './index.scss';
import {
  Button,
  // Checkbox,
  Form,
  Input,
  // Radio,
  // Select,
  TextArea,
} from 'semantic-ui-react';

// const options = [
//   { key: 'm', text: 'Male', value: 'male' },
//   { key: 'f', text: 'Female', value: 'female' },
//   { key: 'o', text: 'Other', value: 'other' },
// ]

class MoleculeWorkWithUsForm extends React.Component {

  render() {
    return (
      <div className="molecule-work-with-us-form work-with-form">
        <Form>
          <Form.Group widths='equal'>
            <Form.Field
              control={Input}
              placeholder='Your Name'
              className="custom-input"
            />
            <Form.Field
              control={Input}
              placeholder='Your Email'
              className="custom-input"
            />
          </Form.Group>

          <Form.Field
            control={TextArea}
            placeholder='Your Message'
            className="custom-input"
          />
          <Form.Field control={Button} className="primary-btn">Send Message</Form.Field>
        </Form>
      </div>
    )
  }
}

export default MoleculeWorkWithUsForm;