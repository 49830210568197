// Basics
import React from 'react';
import './index.scss';
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types';
import { map, size } from 'lodash';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import AtomInfoArea from '../../Atoms/InfoArea';
import MoleculeSectionInfo from '../../Molecules/SectionInfo';

class OrganismGridContent extends React.Component {

  render() {
    const { description, list, title } = this.props

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="organism-grid-content">
        <MoleculeSectionInfo
          title={title}
          description={<HtmlParser html={description} />}
          content={(
            <Grid columns={3} stackable>
              <Grid.Row>
                {
                  map(list, (row, index) => {
                    const { columnDescription, columntitle, iconColor, icon, } = row;
                    return (
                      <Grid.Column key={index}>
                        <AtomInfoArea
                          iconColor={iconColor}
                          description={<HtmlParser html={columnDescription} />}
                          icon={icon}
                          title={columntitle}
                        />
                      </Grid.Column>
                    )
                  })
                }
              </Grid.Row>
            </Grid>
          )}
        />
      </div>
    )
  }
}

OrganismGridContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      columnDescription: PropTypes.string,
      columntitle: PropTypes.string,
      icon: PropTypes.string,
      iconColor: PropTypes.string,
    }),
  ).isRequired,
}

export default OrganismGridContent;