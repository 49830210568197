/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import Gallery from 'react-photo-gallery';
  
class AtomMasonry extends React.Component {
  render() {
    const { photosList } = this.props;
    return (
      <Gallery
        photos={photosList}

      />
    )
  }
}

export default AtomMasonry;

