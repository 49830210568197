// Basics
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react'

// Libraries
import {
  concat,
  get,
  map,
  size
} from 'lodash';
import HtmlParser from './HtmlParser';

// Utilities

// Views
import OrganismBanner from '../components/Organisms/Banner';
import OrganismGridContent from '../components/Organisms/OrganismGridContent';
import OrganismMainContentWrapper from '../components/Organisms/MainContentWrapper';
import OrganismCardContent from '../components/Organisms/OrganismCardContent';
import OrganismTabbedContent from '../components/Organisms/OrganismTabbedContent';
import AtomImg from '../components/Atoms/Img';
import MoleculeSectionInfo from '../components/Molecules/SectionInfo';
import MoleculeListInformation from '../components/Molecules/ListInformation';
import MoleculeTechnologiesCardContainer from '../components/Molecules/TechnologiesCardContainer';
import OrganismSectionOurOffice from '../components/Organisms/SectionOurOffice';
import OrganismTeamSection from '../components/Organisms/SectionTeam';
import OrganismListCentralizedContent from '../components/Organisms/ListCentralizedContent';
import Footer from '../components/Molecules/Footer';
import OrganismBannerGoogleMap from '../components/Organisms/BannerGoogleMap';
import MoleculeContactSection from '../components/Molecules/ContactSection';
import OrganismMap from '../components/Organisms/SectionMap';
import OrganismWorkWithUsSection from '../components/Organisms/SectionWorkWithUs';
import OrganismJobContent from '../components/Organisms/JobContent';

// Libraries
import { normalizeData } from './Utility';

/**
 * Page content object.
 * @param {string} key 
 * @param {Element} content 
 * @param {any} data 
 * @returns {object}
 */
export const PageContentObject = (key, content, data) => {
  return {
    key,
    content,
    data
  };
};

// Formatted options page data
export const AcfOptionsPageData = (pageContent) => {

  // Team profiles
  const teamProfiles = get(pageContent, 'global_templates_office_teams.teams', null);

  // If profiles are present
  let teamProfilesData = null;
  if (size(teamProfiles) > 0) {

    // Normalize data
    teamProfilesData = normalizeData(teamProfiles, true, 'team_unique_key');
  }

  // Contact settings
  const contactSettings = get(pageContent, 'global_templates_website_configuration_contact_settings', null);

  // If contact settings present
  if (contactSettings) {

    // Getting email list
    const contactEmailList = get(contactSettings, 'emails.list', null);

    // If list found
    if (contactEmailList) {
      contactSettings.emails = {
        ...contactSettings.emails,
        'listNormalized': normalizeData(contactSettings.emails.list, true, 'unique_key')
      };
    }
  }

  // Return
  return {
    // getInTouchProfiles: getInTouchProfilesData,
    industryList: get(pageContent, 'global_templates_work_industry_configurations.industries_list', null),
    technologyList: get(pageContent, 'global_templates_technology_configurations.technology_groups', null),
    teamList: teamProfilesData,
    contactSettings
  };
};

export const PageTemplate = ({
  // title, content,
  acf, optionsPage
}) => {

  // console.log('acf', acf);

  // ACF content
  const acfContent = get(acf, 'all_available_sections_page');

  // ACF options page content
  const acfOptionsPageContent = AcfOptionsPageData(get(optionsPage, 'edges.0.node.options'));
  // console.log('acfOptionsPageContent', acfOptionsPageContent);

  // If ACF content is not present
  if (!acf || !acfContent) {
    return null;
  }

  // Defaults
  let bannerSection = null;
  let sectionMiniForm = null;
  let allContent = null;

  // Iterate all content
  map(acfContent, function (row) {

    // Content type name
    const contentType = get(row, 'internal.type');

    // Section ID
    const sectionId = get(row, 'id');

    // Default
    let sectionContent = null;
    const sectionData = null;

    // If header section
    if (contentType === 'WordPressAcf_header_builder') {

      bannerSection = (
        <OrganismBanner
          key={sectionId}
          backgroundImage={get(row, 'element_configuration.section_image')}
          className={get(row, 'element_configuration.section_classes')}
          description={get(row, 'element_configuration.section_content')}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // If header map
    if (contentType === 'WordPressAcf_contact_section_builder') {

      const mapSection = get(acfOptionsPageContent, 'contactSettings');

      // Content type name
      const mapViewType = get(row, 'element_configuration.section_type');

      // If contact page
      if (mapViewType === 'full') {
        bannerSection = (
          <OrganismBannerGoogleMap
            key={sectionId}
            lat={get(mapSection, 'office_location.address_latitude')}
            lng={get(mapSection, 'office_location.address_longitude')}
          />
        );
      }
    }

    // If header map
    if (contentType === 'WordPressAcf_contact_section_builder') {

      const mapSection = get(acfOptionsPageContent, 'contactSettings');
      // Content type name
      const mapViewType = get(row, 'element_configuration.section_type');
      const formHeadView = get(row, 'element_configuration');

      // If contact page
      if (mapViewType === 'full') {
        sectionContent = (
          <MoleculeContactSection
            key={sectionId}
            addressTitle={get(mapSection, 'contact_forms.basic_form.address_section.section_heading')}
            address={get(mapSection, 'office_location.complete_address')}
            emailTitle={get(mapSection, 'contact_forms.basic_form.email_section.section_heading')}
            contactPersonEmail={get(mapSection, 'emails.listNormalized.byId.hello.email')}
            contactPersonName={get(mapSection, 'emails.listNormalized.byId.hello.name')}
            contactTitle={get(formHeadView, 'section_heading')}
            contactDescription={get(formHeadView, 'section_content')}
          />
        );
      }
    }

    // If mini contact
    if (contentType === 'WordPressAcf_contact_section_builder') {

      const mapSection = get(acfOptionsPageContent, 'contactSettings');

      // Content type name
      const mapViewType = get(row, 'element_configuration.section_type');

      // If contact page
      if (mapViewType === 'mini') {
        sectionMiniForm = (
          <OrganismMap
            key={sectionId}
            addressTitle={get(mapSection, 'contact_forms.basic_form.address_section.section_heading')}
            address={get(mapSection, 'office_location.complete_address')}
            emailTitle={get(mapSection, 'contact_forms.basic_form.email_section.section_heading')}
            contactPersonEmail={get(mapSection, 'emails.listNormalized.byId.hello.email')}
            contactPersonName={get(mapSection, 'emails.listNormalized.byId.hello.name')}
            contactTitle={get(mapSection, 'contact_forms.basic_form.section_heading')}
            contactDescription={get(mapSection, 'contact_forms.basic_form.section_content')}
            lat={get(mapSection, 'office_location.address_latitude')}
            lng={get(mapSection, 'office_location.address_longitude')}
          />
        );
      }
    }

    // If mini contact
    if (contentType === 'WordPressAcf_contact_section_builder') {

      // Content work with us
      const mapViewType = get(row, 'element_configuration.section_type');
      const formHeadView = get(row, 'element_configuration');
      // If contact page
      if (mapViewType === 'form') {
        sectionContent = (
          <OrganismWorkWithUsSection
            key={sectionId}
            title={get(formHeadView, 'section_heading')}
            description={get(formHeadView, 'section_content')}
          />
        );
      }
    }


    // If grid content section
    if (contentType === 'WordPressAcf_grid_content_builder') {

      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(row, 'element_configuration.section_grids');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, (gridRow) => {
          return {
            columnDescription: get(gridRow, 'section_content'),
            columntitle: get(gridRow, 'section_heading'),
            icon: get(gridRow, 'icon_configuration.icon_name'),
            iconColor: get(gridRow, 'icon_configuration.icon_color'),
          };
        });
      }

      sectionContent = (
        <OrganismGridContent
          key={sectionId}
          description={get(row, 'element_configuration.section_content')}
          list={gridList}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // If work industry content section
    if (contentType === 'WordPressAcf_work_industries_builder') {

      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(acfOptionsPageContent, 'industryList');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            title: get(gridRow, 'industry_name'),
            icon: get(gridRow, 'icon_configuration.icon_name'),
            iconColor: get(gridRow, 'icon_configuration.icon_color'),
          };
        });
      }

      sectionContent = (
        <OrganismCardContent
          key={sectionId}
          className={get(row, 'element_configuration.section_classes')}
          description={get(row, 'element_configuration.section_content')}
          list={gridList}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // If office team content section
    if (contentType === 'WordPressAcf_team_builder') {

      // Profile key
      const profileKey = row.element_configuration.team_unique_key;
      const profileData = get(acfOptionsPageContent, `teamList.byId.${profileKey}`);
      const profilemamber = get(profileData, `team_members`);

      // Default
      let profileList = null;

      if (size(profilemamber) > 0) {

        // Iterating all rows
        profileList = map(profilemamber, function (gridRow) {
          return {
            designation: get(gridRow, 'member_position'),
            description: get(gridRow, 'member_introduction'),
            image: get(gridRow, 'member_profile_picture'),
            title: get(gridRow, 'member_name'),
            socialProfiles: map(get(gridRow, 'member_social_links'), function (socialRow) {
              return {
                type: get(socialRow, 'platform'),
                link: get(socialRow, 'url'),
              }
            })
          };
        });
      }

      // If list is present
      if (size(profileList) > 0) {
        sectionContent = (
          <OrganismTeamSection
            key={sectionId}
            description={get(profileData, 'team_description')}
            title={get(profileData, 'team_title')}
            list={profileList}
          />
        );
      }
    }

    // If tile content section
    if (contentType === 'WordPressAcf_tile_content_builder') {

      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(row, 'element_configuration.section_tiles');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            title: get(gridRow, 'section_heading'),
            icon: get(gridRow, 'icon_configuration.icon_name'),
            iconColor: get(gridRow, 'icon_configuration.icon_color'),
          };
        });
      }

      sectionContent = (
        <OrganismCardContent
          key={sectionId}
          className={get(row, 'element_configuration.section_classes')}
          description={get(row, 'element_configuration.section_content')}
          list={gridList}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // If list content section
    if (contentType === 'WordPressAcf_list_content_builder') {

      // Default
      let listContent = null;

      // Getting all rows
      const listContentRows = get(row, 'element_configuration.section_rows');
      if (size(listContentRows) > 0) {

        // Iterating all rows
        listContent = map(listContentRows, function (listContentRow) {
          return {
            content: get(listContentRow, 'section_content'),
            title: get(listContentRow, 'section_heading'),
          };
        });
      }

      sectionContent = (
        <MoleculeSectionInfo
          key={sectionId}
          title={get(row, 'element_configuration.section_heading')}
          content={
            <MoleculeListInformation list={listContent} />
          }
        />
      );
    }

    // If list content section
    if (contentType === 'WordPressAcf_list_content_builder') {

      // Default
      let listContent = null;

      // Getting all rows
      const listContentRows = get(row, 'element_configuration.section_rows');
      if (size(listContentRows) > 0) {

        // Iterating all rows
        listContent = map(listContentRows, function (listContentRow) {
          return {
            content: get(listContentRow, 'section_content'),
            title: get(listContentRow, 'section_heading'),
          };
        });
      }

      sectionContent = (
        <MoleculeSectionInfo
          key={sectionId}
          title={get(row, 'element_configuration.section_heading')}
          content={
            <MoleculeListInformation list={listContent} />
          }
        />
      );
    }

    // If job description content
    if (contentType === 'WordPressAcf_list_content_builder') {

      // Default
      let listContent = null;

      // Getting all rows
      const listContentRows = get(row, 'element_configuration.section_rows');
      if (size(listContentRows) > 0) {

        // Iterating all rows
        listContent = map(listContentRows, (listContentRow) => {
          return {
            content: get(listContentRow, 'section_content'),
            title: get(listContentRow, 'section_heading'),
          };
        });
      }

      sectionContent = (
        <MoleculeSectionInfo
          key={sectionId}
          title={get(row, 'element_configuration.section_heading')}
          description={get(row, 'element_configuration.section_content')}
          content={
            <MoleculeListInformation list={listContent} />
          }
        />
      );
    }

    // If job content
    if (contentType === 'WordPressAcf_jobs_builder') {
      sectionContent = (
        <OrganismJobContent key={sectionId} />
      );
    }

    // If gallery content section
    if (contentType === 'WordPressAcf_gallery_builder') {

      // Default
      let gallerylist = null;

      // Getting all rows
      const gallerylistRows = get(row, 'element_configuration.section_images');
      if (size(gallerylistRows) > 0) {

        // Iterating all rows
        gallerylist = map(gallerylistRows, function (gallerylistRow) {
          return {
            src: get(gallerylistRow, 'section_image.localFile.childImageSharp.fluid.srcWebp'),
            width: 6,
            height: 4
          };
        });
      }

      sectionContent = (
        <MoleculeSectionInfo
          key={sectionId}
          title={get(row, 'element_configuration.section_heading')}
          description={<HtmlParser html={get(row, 'element_configuration.section_content')} />}
          content={
            <OrganismSectionOurOffice photos={gallerylist} />
          }
        />
      );
    }


    // If technologies card content section
    if (contentType === 'WordPressAcf_technologies_card_builder') {
      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(acfOptionsPageContent, 'technologyList');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            category: get(gridRow, 'industry_name'),
            item: map(get(gridRow, 'technologies_list'), function (technologyRow, technologyIndex) {
              // Return
              return {
                key: technologyIndex,
                title: get(technologyRow, 'technology_name'),
              };
            }),
          };
        });
      }

      sectionContent = (
        <MoleculeSectionInfo
          key={sectionId}
          title={get(row, 'element_configuration.section_heading')}
          content={
            <MoleculeTechnologiesCardContainer list={gridList} />
          }
        />
      );
    }

    // If list centralized content section
    if (contentType === 'WordPressAcf_list_centralized_content_builder') {
      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(row, 'element_configuration.section_rows');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            title: get(gridRow, 'section_heading'),
            description: get(gridRow, 'section_content'),
          };
        });
      }

      sectionContent = (
        <OrganismListCentralizedContent
          key={sectionId}
          list={gridList}
        />
      );
    }

    // If technologies content section
    if (contentType === 'WordPressAcf_technologies_builder') {

      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(acfOptionsPageContent, 'technologyList');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            title: get(gridRow, 'industry_name'),
            content: size(get(gridRow, 'technologies_list')) > 0
              ? (
                <div className="tab-content">
                  <Grid>
                    <Grid.Row>
                      {
                        map(get(gridRow, 'technologies_list'), function (technologyRow, technologyIndex) {
                          return (
                            <Grid.Column computer={2} tablet={3} mobile={5} key={technologyIndex}>
                              <div className="technologies-column">
                                <AtomImg src={get(technologyRow, 'icon_configuration.icon_image.source_url')} />
                                <h4>{get(technologyRow, 'technology_name')}</h4>
                              </div>
                            </Grid.Column>
                          )
                        })
                      }
                    </Grid.Row>
                  </Grid>
                </div>
              ) : null,
          };
        });
      }

      sectionContent = (
        <OrganismTabbedContent
          key={sectionId}
          className={get(row, 'element_configuration.section_classes')}
          description={get(row, 'element_configuration.section_content')}
          list={gridList}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // If tab content section
    if (contentType === 'WordPressAcf_tab_content_builder') {

      // Default
      let gridList = null;

      // Getting all rows
      const gridRows = get(row, 'element_configuration.section_tabs');
      if (size(gridRows) > 0) {

        // Iterating all rows
        gridList = map(gridRows, function (gridRow) {
          return {
            title: get(gridRow, 'section_heading'),
            content: size(get(gridRow, 'content_list')) > 0
              ? (
                <div className="tab-content">
                  <Grid>
                    <Grid.Row>
                      {
                        map(get(gridRow, 'content_list'), function (technologyRow, technologyIndex) {
                          return (
                            <Grid.Column computer={2} tablet={8} mobile={16} key={technologyIndex}>
                              <div className="technologies-column">
                                <AtomImg src={get(technologyRow, 'icon_configuration.icon_image.source_url')} />
                                <h4>{get(technologyRow, 'section_heading')}</h4>
                              </div>
                            </Grid.Column>
                          )
                        })
                      }
                    </Grid.Row>
                  </Grid>
                </div>
              ) : null,
          };
        });
      }

      sectionContent = (
        <OrganismTabbedContent
          key={sectionId}
          className={get(row, 'element_configuration.section_classes')}
          description={get(row, 'element_configuration.section_content')}
          list={gridList}
          title={get(row, 'element_configuration.section_heading')}
        />
      );
    }

    // Return
    allContent = concat(
      allContent,
      [PageContentObject(
        contentType,
        sectionContent,
        sectionData
      )]
    );
  });

  // Return all content
  return [
    bannerSection,
    <OrganismMainContentWrapper>
      <Container>
        {map(allContent, 'content')}
      </Container>
      {sectionMiniForm}
      <Container>
        <Footer />
      </Container>
    </OrganismMainContentWrapper>
  ];
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}
