// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Utilities
import {toNumber} from 'lodash';

// View
import AtomGoogleMap from '../../Atoms/GoogleMap';


class OrganismBannerGoogleMap extends React.Component {
  render() {
    const { lat, lng } = this.props;
    return (
      <div className="organism-banner-googlemap">
        <AtomGoogleMap
          lat={toNumber(lat)}
          lng={toNumber(lng)}
        />
      </div>
    )
  }
}

OrganismBannerGoogleMap.propTypes = {
  lat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lng: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default OrganismBannerGoogleMap;

