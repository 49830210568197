// Basics
import React from 'react';
import './index.scss';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import MoleculeTeam from '../../Molecules/Team';

// Image
import Team from '../../../assets/images/team.jpg';
import Team2 from '../../../assets/images/team2.jpg';
import Team3 from '../../../assets/images/team3.jpg';
import Team4 from '../../../assets/images/team4.jpg';


class OrganismSectionTeam extends React.Component {

  render() {
    const { description, list, title } = this.props;
    return (
      <div className="organisms-section-team">
        <MoleculeSectionInfo
          title={title}
          description={<HtmlParser html={description} />}
          content={(
            <MoleculeTeam
              list={list}
            />
          )}
        />
      </div>
    )
  }
}
 

export default OrganismSectionTeam;