/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import MoleculeSectionInfo from '../../Molecules/SectionInfo';
import MoleculeIndustriesGirdColumn from '../../Molecules/IndustriesGirdColumn';

class OrganismCardContent extends React.Component {
  render() {
    const {
      title,
      description,
      list
    } = this.props;

    return (
      <div className="organis-card-content">
        <MoleculeSectionInfo
          title={title}
          description={
            <HtmlParser html={description} />
          }
          content={(
            <div>
              <MoleculeIndustriesGirdColumn
                list={list}
              />
            </div>
          )}
        />
      </div>
    )
  }
}

OrganismCardContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      iconColor: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
};

export default OrganismCardContent;
