/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Image, Grid } from 'semantic-ui-react'
import { map } from 'lodash';

// View 
import AtomIcon from '../../Atoms/Icon';
import AtomCard from '../../Atoms/Card';



class MoleculeIndustriesGirdColumn extends React.Component {
  render() {
    const { list } = this.props;
    return (
      <Grid className="molecule-industries-gird-column">
        <Grid.Row>
          {
            map(list, function (row, index) {
              const { iconImage, iconColor, icon, title } = row;
              return (
                <Grid.Column key={index} computer={4} tablet={8} mobile={16}>
                  <AtomCard className="industries-card">
                    <span className="icon">
                      {
                        iconImage &&
                        <Image src={iconImage} alt="Apartment" />
                      }
                      {
                        icon &&
                        <AtomIcon
                          iconColor={iconColor}
                          icon={icon}
                        />
                      }
                    </span>
                    {
                      title &&
                      <p>{title}</p>
                    }

                  </AtomCard>
                </Grid.Column>

              )
            })
          }

        </Grid.Row>
      </Grid>

    )
  }
}

MoleculeIndustriesGirdColumn.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    iconImage: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
  })).isRequired
}

export default MoleculeIndustriesGirdColumn