// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import AtomAnimation from '../Animation';

class AtomCard extends React.Component {
  render() {
    const { children, className } = this.props;
    return (
      <AtomAnimation>
        <div className={`atom-card ${className}`}>
          {children}
        </div>
      </AtomAnimation>
    )
  }
}

AtomCard.defaultProps = {
  className: ''
};

AtomCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default AtomCard;
