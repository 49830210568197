// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';
import { Icon } from 'semantic-ui-react'

const SocialWebsites = {
  twitter: {
    className: 'twitter',
    icon: 'twitter'
  },
  facebook: {
    className: 'facebook',
    icon: 'facebook square'
  },
  google: {
    className: 'google',
    icon: 'google'
  },
};

class AtomSocial extends React.Component {
  render() {
    const { list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="atom-social">
        {
          map(list, function (row, index) {
            const { link, type } = row;

            // Finding social object
            const socialLinkObject = SocialWebsites[type];
            const { className, icon } = socialLinkObject;

            return (
              <a
                className={className}
                key={index}
                href={link}
                rel="noreferrer noopener"
                target="_blank"
              >
                <Icon name={icon} />
              </a>
            )
          })
        }

      </div>
    )
  }
}

AtomSocial.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      type: PropTypes.oneOf(['facebook', 'google', 'linkedin', 'twitter']).isRequired,
    }),
  ).isRequired,
}

export default AtomSocial;

