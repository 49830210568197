// Basics
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

// Package
import GoogleMapReact from 'google-map-react';

// Assets
// import IconMapPin from '../../../assets/images/map-pin.svg';

// Google map key
// const GoogleMapKey = process.env.GATSBY_GOOGLE_MAP_KEY;
const GoogleMapKey = 'AIzaSyCcXbyjPBTr-zm7ZVBcKV-SFJrfOZdJPRU';

// // Map pin marker
// const MapPinComponent = ({ text }) => {

//     return (
//         <div className="google-map-pin-container">
//             <img
//                 className="google-map-pin-icon"
//                 src={IconMapPin}
//                 alt="Location pin"
//             />
//         </div>
//     );
// };

// Map styles (not in use)
// const MapStyles = [
//     {
//         featureType: "poi",
//         elementType: "labels",
//         stylers: [
//             { visibility: "off" }
//         ]
//     }
// ];

// Map config for initialization
const CreateMapOptions = (maps) => {
    return {
        fullscreenControl: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        zoomControl: false,
        // style: MapStyles,
    }
};

class AtomGoogleMap extends React.Component {

    constructor(props) {
        super(props);
        const { lat, lng } = props;

        // Setting state
        this.state = {
            position: {
                lat,
                lng,
            }
        }

        // Bindings
        this.renderMarkers = this.renderMarkers.bind(this);
    }

    renderMarkers(map, maps) {
        const {position} = this.state;

        // New marker
        return new maps.Marker({
            position,
            map
        });
    }

    render() {
        const {zoom} = this.props;
        const {position} = this.state;

        return (
            <div className="atom-google-map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: GoogleMapKey }}
                    defaultCenter={position}
                    defaultZoom={zoom}
                    options={CreateMapOptions}
                    onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                />
            </div>
        );
    }
}

AtomGoogleMap.defaultProps = {
    zoom: 15,
};

AtomGoogleMap.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    zoom: PropTypes.number,
};

export default AtomGoogleMap;
