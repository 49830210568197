// Basics
import React from 'react';
import './index.scss';
import { Link } from 'gatsby';

class MoleculesJobList extends React.Component {
  render() {

    return (
      <div className="table-responsive-lg careers-table">
        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <th scope="row">
                <div className="media align-items-center">
                  <span className="avatar avatar-lg bg-pink rounded mr-4">
                    FD
                  </span>
                  <div className="media-body">
                    <Link to="/careers-single" className="h5 mb-0">
                      Front End Developer
                    </Link>
                  </div>
                </div>
              </th>

              <td>
                <span className="h6">Full time</span>
              </td>
              <td>
                <i className="map marker alternate icon" />
                <span className="h6">San Francisco, US</span>
              </td>
            </tr>
            <tr className="table-divider" />
          </tbody>
        </table>
      </div>
    )
  }
}

export default MoleculesJobList;
