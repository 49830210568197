/* eslint-disable react/jsx-one-expression-per-line */
// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import Icon from "@material-ui/core/Icon";


class AtomIcon extends React.Component {
  render() {
    const { iconColor, icon } = this.props;
    return (
      <div className="atom-icon"><Icon className={iconColor}>{icon}</Icon></div>
    )
  }
}

AtomIcon.propTypes = {
  iconColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
}


export default AtomIcon;

