// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

// Utilities
import { toNumber } from 'lodash';

// View
import AtomGoogleMap from '../../Atoms/GoogleMap';
import MoleculeContactUs from '../../Molecules/ContactUs';

class OrganismSectionMap extends React.Component {

  render() {
    const {
      address,
      addressTitle,
      contactPersonEmail,
      contactPersonName,
      emailTitle,
      lat,
      lng
    } = this.props;

    return (
      <div className="organism-section-map">
        <AtomGoogleMap
          lat={toNumber(lat)}
          lng={toNumber(lng)}
        />
        <MoleculeContactUs
          address={address}
          addressTitle={addressTitle}
          contactPersonEmail={contactPersonEmail}
          contactPersonName={contactPersonName}
          emailTitle={emailTitle}
        />
      </div>
    )
  }
}

OrganismSectionMap.propTypes = {
  lat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  lng: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  address: PropTypes.string,
  addressTitle: PropTypes.string,
  emailTitle: PropTypes.string,
  contactPersonEmail: PropTypes.string,
  contactPersonName: PropTypes.string,
}

export default OrganismSectionMap;