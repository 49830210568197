// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { map, size } from 'lodash';

class AtomList extends React.Component {
  render() {
    const { className, list } = this.props;

    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className={`atom-list list ${className}`}>
        <ul>
          {
            map(list, function (row, index) {
              const { title } = row;
              return (
                <li key={index}>{title}</li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

AtomList.default = {
  className: '',
}


AtomList.propTypes = {
  className: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default AtomList;

