// Basics
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { get, map, size } from 'lodash';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// Views
import AtomList from '../../Atoms/List';
import AtomAnimation from '../../Atoms/Animation';

class MoleculeListInformation extends React.Component {

  render() {
    const { list } = this.props;
    // If list is empty
    if (size(list) < 1) {
      return null;
    }

    return (
      <div className="molecule-list-information">
        {
          map(list, function (row, index) {
            const { content, title } = row;

            return (
              <AtomAnimation>
                <div
                  className="section-row"
                  key={index}
                >
                  {
                    title &&
                    <h3>{title}</h3>
                  }
                  {
                    content && (
                      <AtomAnimation>
                        <div className="list-information-content">
                          <HtmlParser html={content} />
                        </div>
                      </AtomAnimation>
                    )
                  }
                  {
                    get(row, 'list') && (
                      <AtomAnimation>
                        <AtomList
                          className="list-roman"
                          list={row.list}
                        />
                      </AtomAnimation>
                    )
                  }
                </div>
              </AtomAnimation>
            );
          })
        }
      </div>
    )
  }
}

MoleculeListInformation.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default MoleculeListInformation;