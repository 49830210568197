// Basics
import React from 'react';
import './index.scss';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import MoleculeWorkWithUsForm from '../WorkWithUsForm';
import AtomAddressInfo from '../../Atoms/AddressInfo';
import AtomAnimation from '../../Atoms/Animation';

class MoleculeContactSection extends React.Component {

  render() {
    const {
      address,
      addressTitle,
      contactPersonEmail,
      contactPersonName,
      emailTitle,
      contactTitle,
      contactDescription
    } = this.props;

    return (
      <div className="molecule-contact-section">
        <Grid>
          <Grid.Row>
            <Grid.Column computer={10} tablet={10} mobile={16}>
              <AtomAnimation>
                <div className="contact-left">
                  {
                    contactTitle &&
                    <h3>{contactTitle}</h3>
                  }
                  {
                    contactDescription &&
                    <div className="small-text"><HtmlParser html={contactDescription} /></div>
                  }
                  <MoleculeWorkWithUsForm />
                </div>
              </AtomAnimation>
            </Grid.Column>
            <Grid.Column computer={6} tablet={6} mobile={12}>
              <AtomAnimation>
                <div className="contact-right">
                  <AtomAddressInfo
                    iconColor="primary"
                    icon="mail"
                    title={emailTitle}
                    content={
                      <p>
                        {contactPersonName}<br />
                        {contactPersonEmail}
                      </p>
                    }
                  />
                  <AtomAddressInfo
                    iconColor="primary"
                    icon="developer_mode"
                    title={addressTitle}
                    content={<HtmlParser html={address} />}
                  />
                </div>
              </AtomAnimation>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}


MoleculeContactSection.propTypes = {
  address: PropTypes.string,
  addressTitle: PropTypes.string,
  emailTitle: PropTypes.string,
  contactPersonEmail: PropTypes.string,
  contactPersonName: PropTypes.string,
  contactTitle: PropTypes.string,
  contactDescription: PropTypes.object,
}

export default MoleculeContactSection;

