// Basics
import React from 'react';
import './index.scss';
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types';

// Libraries
import HtmlParser from '../../../libraries/HtmlParser';

// View
import AtomCard from '../../Atoms/Card';
import MoleculeWorkWithUsForm from '../WorkWithUsForm';
import AtomAddressInfo from '../../Atoms/AddressInfo';

class MoleculeContactUs extends React.Component {
  render() {
    const {
      address,
      addressTitle,
      contactPersonEmail,
      contactPersonName,
      emailTitle,
    } = this.props;
    return (
      <div className="molecule-contact-us">
        <AtomCard>
          <div className="contact-us-head">Contact Us</div>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <AtomAddressInfo
                  iconColor="primary"
                  icon="mail"
                  title={emailTitle}
                  content={
                    <p>
                      {contactPersonName}<br />
                      {contactPersonEmail}
                    </p>
                  }
                />
              </Grid.Column>

              <Grid.Column computer={8} tablet={8} mobile={16}>
                <AtomAddressInfo
                  iconColor="primary"
                  icon="developer_mode"
                  title={addressTitle}
                  content={<HtmlParser html={address} />}
                />
              </Grid.Column>

            </Grid.Row>
          </Grid>
          <MoleculeWorkWithUsForm />
        </AtomCard>
      </div>
    )
  }
}


MoleculeContactUs.propTypes = {
  address: PropTypes.string,
  addressTitle: PropTypes.string,
  emailTitle: PropTypes.string,
  contactPersonEmail: PropTypes.string,
  contactPersonName: PropTypes.string,
}



export default MoleculeContactUs;

